import React from "react";
import styled from "styled-components";
import { Layout } from "../components/containers";
// import SEO from "../components/marketing/Seo";
import { SectionTitle } from "../components/utility";

const TermOfService = () => {
  return (
    <Layout>
      {/* <SEO title="Terms of Use" /> */}
      <Cover>
        <Wrapper>
          <SectionTitle>Digital Comparison Website Terms of Use</SectionTitle>
          <SubWrapper />
          <Paragraph>
            Welcome to Digital Comparison. Digital Comparison is a website that
            allows customers to submit requests for quotes and information from
            third party suppliers of products and services. We do not supply the
            goods or services the subject of those requests. We pass on requests
            for quotes and information to third party suppliers. You hereby
            consent to us forwarding your Quote Requests to any Suppliers, in
            our absolute discretion and to otherwise use the information in your
            Quote Requests for the purposes described in Our Privacy Policy. You
            acknowledge that We may contact You on Your mobile phone numbers by
            calling or sending an SMS regarding Your Quote Requests and also to
            promote third party goods and services.
          </Paragraph>
          <Paragraph>
            Please read this document carefully. It governs Your commercial
            relationship with Us and sets out legally binding provisions which
            regulate Your use of Our Website (including any desktop and mobile
            versions of it).
          </Paragraph>
          <Paragraph>
            To assist You, We have prepared summaries of Our Terms of Use. Our
            summaries are in the right hand column below. The summaries are not
            a substitute for reading the operative provisions of Our Terms of
            Use (i.e those in the left hand column below). The operative
            provisions of Our Terms of Use are legally binding. Our summaries
            are not legally binding, and do not limit the scope, operation, and
            specifically are not to be used as a mechanism for resolving any
            ambiguity in the operative provisions (if any).
          </Paragraph>
          <Paragraph>
            Please see the Dictionary at clause 13 of these Terms of Use for
            definitions and interpretation provisions that apply to these Terms
            of Use.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            By using Our Website, You agree to Our Terms of Use. We may change
            Our Terms of Use at any time.
          </Title>
          <SubTitle>
            1. Acceptance and modification of these Terms of Use
          </SubTitle>
          <Paragraph>
            1.1. You may only access, browse and/or use Our Website (including
            any desktop and mobile versions of it) if You accept these Terms of
            Use. By accessing, browsing and/or using Our Website, You will be
            deemed to have confirmed that You have read and understood, and
            wholly and unconditionally agree to be legally bound by, and accept,
            these Terms of Use and any information linked to from these Terms of
            Use (including Our Privacy Policy).
          </Paragraph>
          <Paragraph>
            1.2. We may modify and/or replace these Terms of Use and any
            information linked to from these Terms of Use (including Our Privacy
            Policy) from time to time without notice.
          </Paragraph>
          <Paragraph>
            1.3. We will always upload the latest version of these Terms of Use
            to this webpage.
          </Paragraph>
          <Paragraph>
            1.4. If You do not wish to accept these Terms of Use, You must not
            and cannot use the Website or any part of it.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>Our Website might go offline from time to time.</Title>
          <SubTitle>2. Availability of Website</SubTitle>
          <Paragraph>
            2.1. We do not represent that the Website will be available on an
            uninterrupted or error-free basis. We may need to take down the
            Website from time to time.
          </Paragraph>
          <Paragraph>
            2.2. You agree and acknowledge that the accessibility and use of the
            Website is highly dependent on the proper function of the Internet
            and any other computer and telecommunications networks and
            infrastructure upon which the Website operates, interfaces with or
            connects to, and that We are not responsible for any non-performance
            of the Website associated with any of those matters.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            We own the Website and all Intellectual Property Rights in the
            Website. You cannot infringe Our Intellectual Property Rights. You
            must comply with all applicable laws. We are not responsible for any
            Suppliers, their goods or services or any information advertised or
            displayed on the Website about them.
          </Title>
          <SubTitle>3. Website usage restrictions</SubTitle>
          <Paragraph>
            3.1. You may not make any use of the Website except as permitted by
            these Terms of Use and may not do or authorise the commission of any
            act that would or might invalidate or be inconsistent with Our
            Intellectual Property Rights in the Website. Without limiting the
            foregoing provisions, You must not, under any circumstances, sell or
            resell access to the Website or scrape, republish, mirror or
            otherwise rent, lend, lease, sell, redistribute, sublicense, copy or
            duplicate the Website or any content You obtain via the Website. In
            addition, You must not, nor may You permit any person to:
            <br /> (a) copy, alter, modify, adapt, reproduce, republish, frame,
            translate, reverse assemble, reverse engineer, reverse compile,
            transfer, sell, license, create derivative works from or enhance the
            Website and/or any content in the Website (except as expressly
            permitted by the Copyright Act 1968 (Cth)); <br />
            (b) do any act that would or might invalidate or be inconsistent
            with Our Intellectual Property Rights or those of Our licensors;{" "}
            <br />
            (c) use the Website in any way that infringes Our rights or the
            rights of any third party; <br />
            (d) use the Website to create any product or service that competes
            with the Website; or <br /> (e) take any steps to circumvent any
            technological protection measure or security measures in the
            Website.
          </Paragraph>
          <Paragraph>
            3.2. You must not use the Website or any part of the Website in any
            way which is in breach of any statute, regulation, law or legal
            right of any person.
          </Paragraph>
          <Paragraph>
            3.3. You must not use the Website or any part of the Website in
            breach of these Terms of Use.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            You cannot use the website for any illegal purpose or to violate any
            person's legal rights.
          </Title>
          <SubTitle>4. Acceptable Use Policy</SubTitle>
          <Paragraph>
            4.1. You may only use the Website to submit Quote Requests to us.
          </Paragraph>
          <Paragraph>
            4.2. You agree that the following is strictly prohibited by these
            Terms of Use: <br />
            (a) using the Website to violate all or any legal rights of any
            person or company or other entity in any jurisdiction;
            <br /> (b) using the Website in relation to crimes such as theft and
            fraud;
            <br /> (c) using the Website in breach of laws relating to the
            protection of copyright, trade secrets, patents or other
            intellectual property and laws relating to spam or privacy and
            whether such violation is by way of the installation or distribution
            of "pirated" software or otherwise;
            <br />
            (d) introduction of malicious programs into Our network or servers
            (e.g., viruses, worms, Trojan horses, e-mail bombs; <br />
            (e) using the Website to carry out security breaches or disruptions
            of network communication. Security breaches include accessing data
            of which You are not an intended recipient or logging into a server
            or account that You are not expressly authorized to access or
            corrupting any data, network sniffing, pinged floods, packet
            spoofing, denial of service, and forged routing information for
            malicious purposes;
            <br /> (f) using the Website to execute any form of network
            monitoring which will intercept data not intended for You;
            <br /> (g) using the Website to circumvent user authentication or
            security of any of Our hosts, networks or accounts or those of Our
            customers or suppliers;
            <br /> (h) using the Website to interfere with or deny service to
            anyone;
            <br />
            (i) using any program/script/command, or sending messages of any
            kind, with the intent to interfere with, or disable, any person's
            use of the Website;
            <br />
            (j) sending unsolicited email messages through or to users of the
            Website in breach of the Spam Act 2003(Cth);
            <br />
            (k) using the Website to send any form of harassment via email, or
            any other form of messaging, whether through language, frequency, or
            size of messages; and <br />
            (l) using the Website in breach of any person's privacy (such as by
            way of identity theft or "phishing"), (collectively, Our Acceptable
            Use Policy).
          </Paragraph>
          <Paragraph>
            4.3. We may terminate and/or suspend Your access and/or use of Our
            Website if You repeatedly infringe Our Acceptable Use Policy and You
            must indemnify Us for and against any loss and damage that We may
            suffer as a result of Your breach of Our Acceptable Use Policy.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            We own the website and all IP in the website. We also own all
            improvement suggestions that You make regarding the Website.
          </Title>
          <SubTitle>5. Intellectual Property Rights</SubTitle>
          <Paragraph>
            5.1. You agree and acknowledge that these Terms of Use do not
            transfer or assign any Intellectual Property Rights to You.
          </Paragraph>
          <Paragraph>
            5.2. As between You and Us, We own all Intellectual Property Rights
            in the Website.
          </Paragraph>
          <Paragraph>
            5.3. You have no rights in the Website or in any part of it or in
            any modification or enhancement thereof, other than the rights
            temporarily granted to You pursuant to these Terms of Use.
          </Paragraph>
          <Paragraph>
            5.4. You agree that any Intellectual Property Rights in any
            comments, request forms, forum posts, public messages, reviews
            and/or ratings that You may provide to Us in connection with the
            Website or requests for new Website features (each, an Improvement
            Suggestion) becomes Our sole and exclusive property immediately upon
            You uploading or posting that Improvement Suggestion to the Website
            or otherwise providing the Improvement Suggestion to Us, and You
            hereby assign all Intellectual Property Rights in all and any such
            Improvement Suggestions to Us effective as soon as You provide each
            Improvement Suggestion to Us or upload or post an Improvement
            Suggestion to the Website. The assignment pursuant to this clause
            includes an assignment of future copyright pursuant to section 197
            of the Copyright Act 1968 (Cth) and in equity.
          </Paragraph>
          <Paragraph>
            5.5. You irrevocably consent to the infringement by Us and any third
            party We authorise, of all Moral Rights that You may have in any
            Improvement Suggestions.
          </Paragraph>
          <Paragraph>
            5.6. You must not take any step to invalidate or prejudice Our (or
            Our licensors') Intellectual Property Rights in the Website or
            otherwise.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            We cannot be held responsible for the conduct of Our users.
          </Title>
          <SubTitle>6. Responsibility for other users</SubTitle>
          <Paragraph>
            6.1. We do not accept responsibility for the conduct of any users of
            Our Website.
          </Paragraph>
          <Paragraph>
            6.2. If You believe that another user of Our Website has breached
            these Terms of Use please contact Us.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            We are not responsible for Suppliers or their goods or services.
          </Title>
          <SubTitle>7. Disclaimer and release from claims</SubTitle>
          <Paragraph>
            7.1. We do not warrant that any information concerning any third
            party goods and/or services that is advertised or displayed on the
            Website is accurate, error-free or up-to-date. You must not rely on,
            any prices, information, descriptions and/or representations made on
            Our Website concerning any third party goods and/or services
            (Supplier Content), without independently verifying the validity and
            accuracy of such Supplier Content with the relevant Supplier.
          </Paragraph>
          <Paragraph>
            7.2. We do not examine, determine, consider or warrant the
            certification, licensing, solvency, performance, lawfullness of any
            Supplier and/or of any information provided to us by any Supplier.
            Suppliers are not under our control. You must independently assess
            the suitability of any Supplier who You wish to procure goods and/or
            services from. We do not recommend or endorse any Suppliers and We
            are not responsible for their sales or marketing practices or the
            goods and/or services that they offer or supply or fail to offer or
            supply.
          </Paragraph>
          <Paragraph>
            7.3. We are not responsible for, and We do not recommend or endorse,
            any goods and/or services advertised, promoted, sold or otherwise
            provided by any Supplier. Without limiting the foregoing provisions,
            We do not represent that any Suppliers will provide competitive
            quotes or that the goods and/or services that they provide will meet
            Your specific requirements or comply with applicable law. You must
            independently assess the suitability of any Supplier goods and
            services prior to purchasing any of them.
          </Paragraph>
          <Paragraph>
            7.4. We may receive commission or an introductory fee from Suppliers
            who You purchase good and/or services from.
          </Paragraph>
          <Paragraph>
            7.5. You agree and acknowledge that we are not responsible for, and
            You hereby release Us, from and against, all and any claims,
            complaints and legal action that You may otherwise have or take in
            connection with any: <br />
            (a) representations, misrepresentations, conduct and other acts
            and/or omissions of any Supplier; and/or
            <br /> (b) Supplier goods and/or services and/or their advertising
            and/or sales and/or marketing practices.
          </Paragraph>
          <Paragraph>
            7.6. We do not represent, recommend or endorse any websites to which
            We have linked from the Website via hyperlink or otherwise.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            You Consent to Us forwarding your Quote Requests to any Suppliers
            and to otherwise use the information in the Quote Requests for the
            purposes described in Our Privacy Policy.
          </Title>
          <SubTitle>8. Your Consent</SubTitle>
          <Paragraph>
            8.1. You hereby consent to us forwarding your Quote Requests to any
            Suppliers, in our absolute discretion and to otherwise use the
            information in your Quote Requests for the purposes described in Our
            Privacy Policy. You acknowledge that We may contact You on Your
            mobile phone numbers by calling or sending an SMS regarding Your
            Quote Requests and also to promote third party goods and services.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>Our liability is limited in many ways.</Title>
          <SubTitle>9. Liability</SubTitle>
          <Paragraph>
            9.1. Except in respect of any Non-Excludable Guarantees, We do not
            represent that the information on Our Website or any Supplier
            Content is accurate, correct, up-to-date or error free.
          </Paragraph>
          <Paragraph>
            9.2. The information on the Website is not professional advice. You
            agree that You will seek all appropriate financial, legal and other
            advice as applicable before relying on any information You obtain
            from the Website.
          </Paragraph>
          <Paragraph>
            9.3. To the extent possible by law, We are not liable to You for any
            loss or damage incurred by You, for loss of profits, loss of
            business or other opportunity, loss of savings, or loss of data.
          </Paragraph>
          <Paragraph>
            9.4. To the extent that Our liability is not otherwise excluded by
            these Terms of Use, subject to any Non- Excludable Guarantees, Our
            liability to You is limited to $100.
          </Paragraph>
          <Paragraph>
            9.5. Any warranty against defects provided by Us to You in Your
            capacity as a 'consumer' under the Australian Consumer Law is in
            addition to Your other rights and remedies under a law in relation
            to the goods or services to which the warranty relates.
          </Paragraph>
          <Paragraph>
            9.6. Where liability for breach of any guarantees under the
            Australian Consumer Law can be limited, Our liability arising from
            any breach of those guarantees (if any) is limited with respect to
            the supply of goods, to the replacement or repair of the goods or
            the costs of resupply or replacement of the goods and/or with
            respect to services, to the supply of services again or cost of
            re-supplying the services again.
          </Paragraph>
          <Paragraph>
            9.7. Except in respect of any Non-Excludable Guarantees, all
            conditions, warranties and guarantees implied in these Terms of Use
            are excluded, to the extent possible by law.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            You and We can terminate Your access to the website under certain
            conditions.
          </Title>
          <SubTitle>10. Termination</SubTitle>
          <Paragraph>
            10.1. We may terminate these Terms of Use and/or Your access to the
            Website or any part of it at any time without notice.
          </Paragraph>
          <Paragraph>
            10.2. We may take down the Website or any part of it or take the
            Website or any part of it offline at any time without notice where
            reasonably necessary to protect Our legitimate commercial interests.
          </Paragraph>
          <Paragraph>
            10.3. Termination of these Terms of Use and access to the Website
            does not affect any accrued rights of either party.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>
            You and We can terminate Your access to the website under certain
            conditions.
          </Title>
          <SubTitle>11. Notices</SubTitle>
          <Paragraph>
            11.1. Any notice issued to You from Us or from Us to You shall be in
            writing and sent by hand delivery, post or email. Where sent from Us
            to You, We shall use any of Your contact details that You provide to
            Us.
          </Paragraph>
          <Paragraph>
            11.2. You may contact Us or send a notice to Us using Our contact
            details that are specified on Our website at
            digitalcomparision.com.au/contact by sending an email to Us at
            contact@digitalcomparison.com.au or by writing to Us at Level 1
            80-82 Bell Street HEIDELBERG HEIGHTS VIC 3081.
          </Paragraph>
          <Paragraph>
            11.3. Any notice issued by hand shall be deemed delivered upon
            delivery.
          </Paragraph>
          <Paragraph>
            11.4. Any notice issued by post shall be deemed delivered 6 Business
            Days after posting if posted domestically, or 20 Business Days after
            posting if posted internationally.
          </Paragraph>
          <Paragraph>
            11.5. Any notice issued via email shall be deemed to be delivered
            upon receipt by the sender of an electronic read receipt or delivery
            receipt, or upon receipt of confirmation from the recipient that the
            recipient received the email.
          </Paragraph>
          <Paragraph>
            11.6. We may send You email or other electronic messages concerning
            the Website from time to time.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>12. General</Title>
          <SubTitle></SubTitle>
          <Paragraph>
            <SubTitle> We reserve Our rights.</SubTitle>
            12.1. Other rights: All rights not expressly granted to Us in these
            Terms of Use are expressly reserved by Us.
          </Paragraph>
          <Paragraph>
            <SubTitle>We can change these Terms of Use at any time.</SubTitle>
            12.2. Amendment: These Terms of Use may be amended by Us at any
            time.
          </Paragraph>
          <Paragraph>
            <SubTitle>
              You cannot transfer Your rights under these Terms of Use unless We
              approve the transfer. We can transfer Our rights and obligations
              at any time.
            </SubTitle>
            12.3. Assignment: You may not assign, transfer, license or novate
            Your rights or obligations under these Terms of Use without Our
            prior written consent. We may assign, transfer, license or novate
            Our rights or obligations under these Terms of Use at any time,
            subject to Our Privacy Policy.
          </Paragraph>
          <Paragraph>
            <SubTitle>
              If part of these Terms of Use are not legally binding, the rest
              still are.
            </SubTitle>
            12.4. Severability: If any part of these Terms of Use is deemed
            invalid by a court of competent jurisdiction, the remainder of these
            Terms of Use shall remain enforceable.
          </Paragraph>
          <Paragraph>
            <SubTitle>
              We are not partners, employers or employees of one another.
            </SubTitle>
            12.5. Relationship: You and Us are independent contracting entities
            and these Terms of Use do not create any relationship of
            partnership, joint venture, fiduciary, or employer and employee or
            otherwise.
          </Paragraph>
          <Paragraph>
            <SubTitle>
              These Terms of Use set out Our entire agreement with You.
            </SubTitle>
            12.6. Entire Agreement: These Terms of Use and any information
            linked to from these Terms of Use constitute the entire agreement
            between You and Us and to the extent possible by law, supersede all
            prior understandings, representations, arrangements and agreements
            between You and Us regarding its subject matter.
          </Paragraph>
          <Paragraph>
            <SubTitle>Any legal action will be commenced in Victoria.</SubTitle>
            12.7. Jurisdiction: These Terms of Use will be interpreted in
            accordance with the laws in force in Victoria. You and Us
            irrevocably submit to the non-exclusive jurisdiction of the courts
            situated in Victoria in relation to any proceedings or disputes
            concerning these Terms of Use.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <Title>Capitalised terms are defined in this clause.</Title>
          <SubTitle>13. Dictionary and Interpretation provisions</SubTitle>
          <SubTitle>13.1. Definition</SubTitle>
          <Paragraph>In these Terms of Use:</Paragraph>
          <Paragraph>
            <Bold>Australian Consumer Law</Bold> means schedule 2 to the
            Competition and Consumer Act 2010 (Cth).
          </Paragraph>
          <Paragraph>
            <Bold>Business Day</Bold> means Monday – Friday excluding public
            holidays in Victoria.
          </Paragraph>
          <Paragraph>
            <Bold>Intellectual Property Rights</Bold> mmeans all copyright,
            trademark rights, patent rights, and design rights, whether
            registered or unregistered, and all other rights to intellectual
            property as defined under article 2 of the convention establishing
            the World Intellectual Property Organization, and all rights to
            enforce any of the foregoing rights.
          </Paragraph>
          <Paragraph>
            <Bold>Moral Rights</Bold> has the meaning given in the Copyright Act
            1968 (Cth).
          </Paragraph>
          <Paragraph>
            <Bold>Non-Excludable Guarantee</Bold> means a non-excludable
            guarantee implied into these Terms of Use by the Australian Consumer
            Law.
          </Paragraph>
          <Paragraph>
            <Bold>Privacy Policy</Bold> means Our Privacy Policy located at
            digitalcomparision.com.au/privacy.
          </Paragraph>
          <Paragraph>
            <Bold>Quote Request</Bold> means any request for quote or
            information that You provide to Us by completing a form on the
            Website concerning any goods and/or services.
          </Paragraph>
          <Paragraph>
            <Bold>Supplier</Bold> means: (a) any third party whose products
            and/or services are advertised or displayed on Our Website; and (b)
            any other third party who We disclose Your Quote Requests to.
          </Paragraph>
          <Paragraph>
            <Bold>Terms of Use</Bold> means the terms and conditions set out on
            this webpage as amended by Us from time to time.
          </Paragraph>
          <Paragraph>
            <Bold>We, Our</Bold> and <Bold>Us</Bold> means AARU PTY LTD [ABN
            98600469896] of Level 1 80-82 Bell Street HEIDELBERG HEIGHTS VIC
            3081.
          </Paragraph>
          <Paragraph>
            <Bold>Website</Bold> means the Digital Comparison website owned
            and/or provided by Us, the homepage URL of which is
            www.digitalcomparision.com.au and also includes any content, images,
            text and other information appearing on any page or screen of that
            website and any source code and object code in that website, and
            also refers to any desktop and mobile versions of the website.
          </Paragraph>
          <Paragraph>
            <Bold>You</Bold> mean you, the person who accesses and/or uses the
            Website for any reason.
          </Paragraph>
        </Wrapper>
        <Wrapper>
          <SubTitle>13.2. Interpretation</SubTitle>
          <Paragraph>In these Terms of Use:</Paragraph>
          <Paragraph>
            In these Terms of Use:
            <br /> (a) Headings and underlinings are for convenience only and do
            not affect the construction of these Terms of Use. <br /> (b) A
            provision of these Terms of Use will not be interpreted against a
            party because the party prepared or was responsible for the
            preparation of the provision, or because the party's legal
            representative prepared the provision. <br />
            (c) Currency refers to Australian dollars unless specified
            otherwise. <br />
            (d) A reference to a statute or regulation includes amendments
            thereto. <br />
            (e) A reference to a clause, subclause or paragraph is a reference
            to a clause, subclause or paragraph of these Terms of Use. <br />{" "}
            (f) A reference to a subclause or paragraph is a reference to the
            subclause or paragraph in the clause in which the reference is made.{" "}
            <br /> (g) A reference to time is to time in Victoria.
            <br />
            (h) A reference to a person includes a reference to an individual, a
            partnership, a company, a joint venture, government body, government
            department, and any other legal entity. <br /> (i) The words
            "includes", "including" and similar expressions are not words of
            limitation. <br /> (j) A reference to “a party” means You or Us (as
            the context dictates) and a reference to “the parties” means You and
            Us.
          </Paragraph>
        </Wrapper>
      </Cover>
    </Layout>
  );
};

export default TermOfService;

const Bold = styled.h3`
  color: #242424;
  font-size: 1.6rem;
  font-weight: bolder;
  font-style: italic;
  display: inline-block;
`;
const SubWrapper = styled.div`
  padding: 2rem 0 0 0;
`;
const Cover = styled.section`
  padding: 15vh 2rem;
  width: 85%;
  margin: auto;
`;

const Wrapper = styled.div`
  padding: 3rem 0 0 0;
  width: 85%;
  margin: auto;
  @media only screen and (max-width: 75em) {
    width: 100%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
`;

const Title = styled.h2`
  padding: ${(props) => props.pad || "1rem 0 0 0"};
  font-family: "Avenir-Medium";
  font-size: 2rem;
  line-height: 2.6rem;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${(props) => props.color || "#242424"};
  @media only screen and (max-width: 75em) {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: ${(props) => props.align || "left"};
  }
`;

const SubTitle = styled(Title)`
  font-size: 1.8rem;
  @media only screen and (max-width: 75em) {
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.6rem;
  }
`;

const Paragraph = styled.p`
  padding: 0.5rem 0;
  font-family: "Avenir-Book";
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #242424;
  @media only screen and (max-width: 75em) {
    font-size: 1.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 1.4rem;
    text-align: ${(props) => props.align || "left"};
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.4rem;
    text-align: ${(props) => props.align || "left"};
  }
`;
